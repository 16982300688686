html, body {
  margin: 0;
  height: 100%;
  background: #eee;
  color: #222;
  font-family: sans-serif;
}

.single-service {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
    font-size: 42px;
  }

  p {
    font-size: 20px;
  }

  p.root-subhead {
    max-width: 350px;
    margin: 15px auto;
  }

  h1, p {
    margin: 15px;
    line-height: 1.3;
  }
}

.container {
  max-width: 800px;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
}

.privacy-policy-container {
  margin: auto;
}

.als {
  color: #3b20e5;
  font-weight: bold;
}